<template>
    <CTabs tabs class="nav-underline nav-underline-primary">
         <CTab active>
           <template slot="title">
             <CIcon name="cil-task" />&nbsp;Filtre
           </template>
           <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol sm="6">
                <CInput
                  label="ID"
                  placeholder="Ürün ID"
                  horizontal
                  type="number"
                  v-on:keyup.enter="filter"
                  v-model="data.id"
                />
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <hr/>
            <CRow>
              <CCol>
                <CInput
                  label="Kayıt Tarihi"
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.startTime"
                />
                <CInput
                  label=" "
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.endTime"
                />
              </CCol>
            </CRow>
            <hr/>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Ürün"
                  placeholder="Ürün Adı"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.name"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Kod"
                  placeholder="Kod"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.code"
                />
              </CCol>
              <CCol sm="6">
                <CSelect
                  label="Ürün Türü"
                  placeholder="Seçiniz.."
                  :options="activeProductTypesWoS"
                  horizontal
                  :value.sync="data.productType"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Model"
                  placeholder="Ürün modeli"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.model"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Üretici"
                  placeholder="Üretici"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.manufacturer"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                  label="Grup"
                  placeholder="Seçiniz"
                  :options="activeProductGroups"
                  horizontal
                  :value.sync="data.productGroup"
                />
              </CCol>
              <CCol sm="6">
                <CSelect
                  label="Tasarımcı"
                  placeholder="Seçiniz"
                  :options="activeDesigners"
                  horizontal
                  :value.sync="data.productDesigner"

                />
              </CCol>
            </CRow>
            <CRow style="margin-top: 20px">
              <CCol sm="6">
                <CSelect
                  label="Aktif"
                  horizontal
                  :options="comboDataList"
                  :value.sync='data.activeFlag'
                >
                </CSelect>
              </CCol>
              <CCol sm="6">
                <CSelect
                  label="Yeni"
                  horizontal
                  :options="comboDataList"
                  :value.sync='data.newFlag'
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow style="margin-top: 20px">
              <CCol sm="6">
                <CSelect
                  label="Clerance"
                  horizontal
                  :options="comboDataList"
                  :value.sync='data.clearanceFlag'
                >
                </CSelect>
              </CCol>
              <CCol sm="6">
                <CSelect
                  label="Category Feed Flag"
                  horizontal
                  :options="comboDataList"
                  :value.sync='data.catalogFeedFlag'
                >
                </CSelect>
              </CCol>
            </CRow>
           </CForm>
         </CTab>
     </CTabs>
 </template>
 
 <script>
    export default{
        name: "ProductFilter",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            filter: Function
        },
        computed:{
          activeProductTypesWoS: function() {
              let data = []
              this.$store.getters.activeProductTypesWoS.map(r => data.push({value:r.id, label: r.name}))
              return data
           },
          activeProductGroups: function(){
            let data = []
            this.$store.getters.activeProductGroups.map(r => data.push({value:r.id, label: r.name}))
            return data
          },
          activeDesigners: function(){
            let data = []
            this.$store.getters.activeDesigners.map(r => data.push({value:r.id, label: r.name}))
            return data
          },
          
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                comboDataList: [
                  {label:"Hepsi", value:""},
                  {label:"Evet", value:"true"},
                  {label:"Hayır", value:"false"}
                ],
            }
        },
        methods:{
             clearFilters: function(){
                 this.data = {}
                 this.filter()
             }
        }
    }
 </script>